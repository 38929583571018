import { h as snabH } from 'snabbdom';
export function onInsert(f) {
    return {
        insert: vnode => f(vnode.elm),
    };
}
export function bind(eventName, f, redraw, passive = true) {
    return onInsert(el => el.addEventListener(eventName, e => {
        const res = f(e);
        if (res === false && !passive)
            e.preventDefault();
        redraw?.();
        return res;
    }, { passive }));
}
export const bindNonPassive = (eventName, f, redraw) => bind(eventName, f, redraw, false);
export function bindSubmit(f, redraw) {
    return bind('submit', e => (e.preventDefault(), f(e)), redraw, false);
}
export const dataIcon = (icon) => ({
    'data-icon': icon,
});
export const iconTag = (icon) => snabH('i', { attrs: dataIcon(icon) });
// '' may be falsy but it's a valid VNode
// 0 may be falsy but it's a valid VNode
const kidFilter = (x) => (x && x !== true) || x === '';
const filterKids = (children) => (Array.isArray(children) ? children : [children]).filter(kidFilter);
/* obviate need for some ternary expressions in renders.  Allows
     looseH('div', [ kids && h('div', 'kid') ])
   instead of
     h('div', [ isKid ? h('div', 'kid') : null ])
   'true' values are filtered out of children array same as 'false' (for || case)
*/
export function looseH(sel, dataOrKids, kids) {
    if (kids)
        return snabH(sel, dataOrKids, filterKids(kids));
    if (!kidFilter(dataOrKids))
        return snabH(sel);
    if (Array.isArray(dataOrKids) || (typeof dataOrKids === 'object' && 'sel' in dataOrKids))
        return snabH(sel, filterKids(dataOrKids));
    else
        return snabH(sel, dataOrKids);
}
